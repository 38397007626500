<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Condicion de Pago" :rules="rules" :headers="headers" :inputs="inputs" />
</template>


<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import {PaymentCondition} from "../../../models/comercial/PaymentCondition";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';


export default {
  props: {
    voucher_type: null
  },
  data() {
    return {
      test: null,
      entity: new PaymentCondition(),
      inputs: [
        new CatalogInput("Nombre", "name", "text"),
        new CatalogInput("Nombre Corto", "key_name", "text"),
        new CatalogInput("Dias de Pago", "days", "number"),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Nombre Corto", "key_name")
      ],
      validate: new ValidateForm(),
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new PaymentCondition(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new PaymentCondition(this.session); 
  }
}
</script>

<style>

</style>